<template>
    <div class="p-3">
        <br>
        <h2>Splošni pogoji poslovanja</h2>
        <a href="documents/SPP_20210927.pdf" target="_blank">
            <b>Splošni pogoji poslovanja.pdf
            <b-icon-download variant="success"></b-icon-download></b>
        </a>
        <br>
        Splošni pogoji poslovanja so sestavljeni v skladu z Obligacijskim zakonikom (OZ), Zakonom o trgovini (ZT-1),
        Zakonom o varstvu potrošnikov (ZVPot), Zakonom o varstvu potrošnikov pred nepoštenimi poslovnimi praksami
        (ZVPNPP), Zakonom o elektronskem poslovanju na trgu (ZEPT), Zakonom o izvensodnem reševanju potrošniških sporov
        (ZisRPS), Uredbo (EU) 2018/302 in mednarodnimi kodeksi e-poslovanja.
        <br>
        Pogoji poslovanja obravnavajo delovanje portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>.
        Njihov cilj je definirati prodajne
        pogoje, pravice uporabnika ter poslovni odnos med ponudnikom in kupcem.
        <br>
        Prleška spletna tržnica je spletna trgovina, ki jo upravlja SMRK, Mojca Tomišić, s.p., spletna tržnica,
        Stanetinci 7, 9244 Sveti Jurij ob Ščavnici.
        <br>
        Ob registraciji v sistem spletnega portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>
        obiskovalec skladno s temi splošnimi pogoji
        poslovanja izbere uporabniško ime, določi geslo ter poda naslov svoje elektronske pošte (v nadaljevanju: obvezni
        podatki). Uporabniško ime in geslo uporabnika nedvoumno določata in povezujeta z vnesenimi podatki. Z
        registracijo obiskovalec postane uporabnik in pridobi pravico do nakupovanja preko spletnega portala.
        <br>
        <h3 class="mt-2">Osnovni pojmi</h3>
        Vsi pojmi za osebe se uporabljajo v moškem spolu za oba spola.
        <br>
        "Prleška spletna tržnica" – tržno ime za spletno tržnico na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>,
        ki je v upravljanju
        podjetja SMRK.
        <br>
        "Upravljalec" – upravljalec Prleške spletne tržnice in spletnega portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>.
        <br>
        "Obiskovalec" – vsaka fizična ali pravna oseba, ki obišče portal <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>,
        vendar ni prijavljena
        kot registrirani uporabnik.
        <br>
        "Uporabnik" – vsak uporabnik, ki se registrira z uporabniškim imenom, geslom in e-naslovom.
        <br>
        "Ponudnik" – pogodbena fizična ali pravna oseba z registrirano dejavnostjo, ki ponuja svoje produkte na portalu
        <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>.
        <br>
        "Kupec" – registrirani uporabnik (fizična ali pravna oseba), ki na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>
        kupi artikle
        iz ponudbe.
        <br>
        "Osebni podatki" – podatki, ki jih kot osebne podatke opredeljuje Zakon o varstvu osebnih podatkov.
        <br>
        "Produkt" – blago, ki ga vpiše in ponuja ponudnik, lahko v različnih pakiranjih in z različnimi cenami.
        <br>
        "Artikel" – produkt z določeno količino, ceno in embalažo, ki se po potrditvi naročila več ne spreminja.
        <br>
        "Naročilo" – oddaja ponudbe za nakup artiklov.
        <br>
        <h3 class="mt-2">Splošne določbe</h3>
        Splošni pogoji poslovanja so veljavni ob vsakem obisku spletne strani.
        <br>
        Z registracijo in ob vsaki prijavi uporabnik sprejme navedene pogoje poslovanja. Ti pogoji poslovanja
        izključujejo kakršnekoli druge pogoje poslovanja z izjemo določil morebitne pisne pogodbe, ki jo na pobudo kupca
        ali upravitelja portala sprejmeta in podpišeta kupec ter upravitelj portala.
        <br>
        Splošni pogoji poslovanja so na voljo na spletnem portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>
        in si jih kupec lahko prenese
        na svojo elektronsko napravo preko internetne povezave, dostopne na spletni strani ponudnika.
        <br>
        Splošni pogoji poslovanja se lahko kadarkoli dopolnijo z novimi določili, zato se je uporabnik dolžan seznaniti
        z njimi ob vsakem nakupu. Vsaka verzija pogojev splošnih poslovanja je posebej datirana. Za posamezno naročilo
        veljajo splošni pogoji poslovanja, ki so objavljeni na spletnem portalu v času oddaje naročila.
        <br>
        Splošne in ostale pogoje poslovanja določa upravljalec strani, ki blago in storitve partnerjev – ponudnikov na
        portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> prodaja v svojem imenu za tuj račun.
        Upravljalec ne odgovarja za objavljene
        vsebine, saj so za njih odgovorni ponudniki, in skupaj s ponudnikom odgovarja za izvedbo naročil skladno z
        objavljenimi pogoji.
        <br>
        Upravljalec se zavezuje, da bo kupcu vselej zagotovil naslednje informacije:
        <ul>
            <li>identiteto ponudnika (ime in sedež podjetja, matična številka),</li>
            <li>kontaktne podatke, ki uporabniku omogočajo hitro in učinkovito komunikacijo (e-pošta, telefon),</li>
            <li>bistvene značilnosti blaga oziroma storitev (vključno s poprodajnimi storitvami in garancijami),</li>
            <li>dostopnost izdelkov (vsak izdelek ali storitev, ki je v ponudbi na spletnem mestu, naj bi bil dostopen v
                roku, ki ga izbere kupec na podlagi ponujenih možnosti ponudnika),
            </li>
            <li>pogoje dostave izdelkov ali izvršitve storitve (način, kraj in rok dostave),</li>
            <li>jasno in nedvoumno določene cene,</li>
            <li>način plačila in dostave,</li>
            <li>rok, v katerem je še možno odstopiti od pogodbe in pogojih za odstop; poleg tega tudi o tem, če in
                koliko stane kupca vračilo izdelka – vse na posebno oz. izrecno zahtevo kupca,
            </li>
            <li>pojasnilo postopka ob pritožbi, vključno z vsemi podatki o kontaktni osebi ali službi za stike s
                kupci.
            </li>
        </ul>
        <h3 class="mt-2">Registracija</h3>
        Obiskovalec se lahko na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> registrira samo kot
        uporabnik z uporabniškim imenom,
        elektronskim naslovom in geslom, lahko pa na portalu nastopa kot fizična oseba, kmetijsko gospodarstvo ali
        pravna oseba. Kupcem priporočamo, da poleg splošnih podatkov podajo še svoj fizični naslov in telefonsko
        številko, na katero so dosegljivi.
        <br>
        <b>Koraki registracije:</b>
        <br>
        1. Vnesemo podatke za registracijo:
        <ul>
            <li>Obvezne podatke: uporabniško ime, geslo in e-mail;</li>
            <li>Neobvezne podatke:</li>
            <li>za fizično osebo (Ime, srednje ime, priimek, davčno številko, pravno obliko, naslov, telefon,
                mobitel),
            </li>
            <li>kmetijo (ime kmetije, domače ime, KMG-MID, davčno številko, pravno številko, naslov, telefon) ali</li>
            <li>pravno osebo (ime podjetja, kratko ime, davčno številko, odgovorno osebo, pravno obliko, naslov,
                telefon);
            </li>
        </ul>
        in obkljukamo:
        <ul>
            <li>da se strinjamo s temi splošnimi pogoji poslovanja, obdelavo osebnih podatkov in da smo polnoletni</li>
            <li>ali želimo prejemati občasna obvestila</li>
            <li>ali želimo postati ponudnik (pri kmetiji ali pravni osebi).</li>
        </ul>
        2. Izberemo avatar.
        <br>
        3. Pregledamo podatke in potrdimo registracijo.
        <br>
        Kupci iz drugih držav Evropske unije vpišejo svoj naslov v okence z naslovom: Naslov kupca iz EU.
        <br>
        Po uspešni registraciji prejmemo e-mail s povezavo, s klikom na katero potrdimo registracijo.
        <br>
        Uporabnik je dolžan zagotoviti, da bo v njegovem imenu in z njegovim geslom nastopal le on oziroma oseba,
        pooblaščena za nastopanje v njegovem imenu.
        <br>
        Ponudniki, ki želijo na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> ponujati svoje
        pridelke ali izdelke, se na spletni strani
        obrnejo na stik, objavljen na vsaki strani portala. Vsi ponudniki morajo imeti s podjetjem SMRK sklenjeno
        pogodbo, preden lahko ponudijo svoje produkte na portalu.
        <br>
        Po opravljeni registraciji uporabnik dobi sistemsko sporočilo na vpisan elektronski naslov, ki ga mora potrditi.
        Šele s tem je registracija opravljena.
        <h3 class="mt-2">Prijava</h3>
        Uporabnik se prijavi na portal <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> s svojim uporabniškim
        imenom in geslom.
        <h3 class="mt-2">Nakup</h3>
        Naročanje na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> poteka v slovenskem jeziku prek
        interneta 24 ur na dan in 7 dni v
        tednu, razen v primeru tehničnih težav ali drugačne odločitve upravljalca strani. Produkte se lahko izbira in
        naroča iz seznama produktov na spletnih straneh. Vsi produkti, navedeni na teh straneh, so na voljo do odprodaje
        zalog oziroma dokler jih ponudnik ne umakne iz ponudbe. Če je kupec izbral produkt, ki ga ponudnik več nima in
        ga ni umaknil iz spletne trgovine, se kupca o tem obvesti na prevzemnem mestu oz. po telefonu oz. po e-pošti.
        <br>
        Naročilo je podano v trenutku, ko kupec s klikom "POTRDI NAKUP" potrdi naročilo.
        <br>
        Cene veljajo v trenutku, ko je oddano naročilo prejel in potrdil informacijski sistem ponudnika.
        <br>
        Nakup na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> je možen samo za uporabnike. Če
        oseba ni registrirana na portalu, nakupa
        ni možno opraviti.
        <br>
        S klikom »POTRDI NAKUP« kupec izjavlja:
        <ul>
            <li>da je v skladu z zakonom starejši od 18 let;</li>
            <li>da mu ni odvzeta poslovna sposobnost.</li>
        </ul>
        Kupoprodajna pogodba je sklenjena v trenutku, ko upravljalec portala potrdi naročilo (kupec prejme elektronsko
        sporočilo: ZADEVA: "Potrdilo o nakupu na Prleški spletni tržnici"). Od tega trenutka so količine, cene in drugi
        pogoji dogovorjeni in veljajo tako za ponudnike kot za kupca.
        <br>
        Kupoprodajna pogodba (tj. elektronsko sporočilo o potrditvi naročila) je v elektronski obliki shranjena na
        strežniku portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> in je dostopna kupcu.
        <br>
        V primeru, da artikla več ni na zalogi oz. ga ni mogoče dobaviti v skladu s pogoji nakupa, se kupca o tem
        obvesti najkasneje do dneva, določenega za prevzem artikla.
        <h3 class="mt-2">Prevzem/Dostava</h3>
        Čas in kraj prevzema naročenih artiklov sta nedvoumno označena že pred nakupom pri izdelku, v košarici ter po
        opravljenem nakupu tudi na naročilnici. Kupec je po opravljenem nakupu dolžen prevzeti svoj nakup na dostavnem
        mestu, na katerega jih je naročil, v času navedenega prevzema.
        <br>
        Kupec na dostavnem mestu pregleda dostavljene artikle, jih plača in prejme račun, lahko pa predhodno poravna
        nakup na TRR, na centru pa samo pregleda artikle in prevzame račun.
        <h3 class="mt-2">Rok dostave</h3>
        Vsa naročila, oddana do izbranega dneva dostave, bodo dostavljena na izbrani dan dostave, po pošti pa v dveh
        delovnih dneh od prejema nakazila na TRR. Dostavo vršimo na datum, ki ga izberete na začetku nakupa, v kraj, ki
        ga izberete skupaj z datumom.
        <br>
        V primeru naročila virtualnega e-izdelka tega prejmete najkasneje v roku enega delovnega dne od prejema nakazila
        na TRR. E-izdelke prejmete na elektronski naslov, ki ste ga navedli na Prleški spletni tržnici.
        <h3 class="mt-2">Stroški dostave</h3>
        Dostava je za naročila nad 50,00 € brezplačna, za naročila v vrednosti od 30,00 € do 49,99 € so stroški dostave
        3,00 €, za naročila v vrednosti do vključno 29,99 € pa so stroški dostave 5,00 €. Stroški so vidni v košarici,
        preverite jih pred potrditvijo nakupa. Stroški pakiranja in dostave po pošti, ki jo vršimo po dogovoru, so za
        dostavo znotraj Republike Slovenije brezplačni za naročila nad 50,00 €, za naročila v vrednosti od 30,00 € do
        49,99 € so stroški pakiranja in dostave 3,00 €, za naročila v vrednosti do vključno 29,99 € pa so stroški
        pakiranja in dostave 5,00 €. Za države EU je strošek poštnine odvisen od višine naročila:
        <br>
        <b-table striped hover :items="items"></b-table>
        <br>

        <table>
            <thead>
            <tr>
                <th class="w-25"></th>
                <th class="w-25">do 29,99 €</th>
                <th class="w-25">30,00–70,00 €</th>
                <th class="w-25">nad 70,00 €</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Avstrija</td>
                <td>5,00 €</td>
                <td>3,00 €</td>
                <td>0,00 €</td>
            </tr>
            <tr>
                <td>Nemčija</td>
                <td>5,00 €</td>
                <td>3,00 €</td>
                <td>0,00 €</td>
            </tr>
            </tbody>
        </table>
        <br>
        V primeru spremembe cene dostave kupca na to pred dostavo opozorimo in nakup se izvede, ko potrdi novo ceno.
        <br><br>
        <h3 class="">Obvestilo</h3>
        <p class="text-danger">Zaradi izrednih razmer v zvezi s pandemijo dostavo do nadaljnjega vršijo dostavne službe.</p>
        <h3 class="mt-2">Potek naročanja</h3>
        Naročilo lahko izvede obiskovalec portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>, po tem ko
        se registrira in prijavi (kot
        obiskovalec si lahko ogleda produkte in ponudnike, ne more pa opraviti naročila).
        <h4 class="mt-2">1. Izberemo kraj in čas prevzema</h4>
        Kupec si najprej izbere kraj in čas prevzema, kjer bo prevzel naročene artikle. Načeloma so artikli na voljo na
        vseh centrih, nekaterim artiklom pa bi z daljšim transportom upadla vrednost, zato so na voljo samo v določenih
        centrih. Prav tako je potrebno izbrati datum prevzema, saj so nekateri produkti na voljo le v določenih
        terminih.
        <h4 class="mt-2">2. Dodamo produkte v košarico</h4>
        Kupec izbira med različnimi produkti, razporejenimi v skupine (Zelenjava, Sadje, Kmečka kuhna, Mlin ...) in
        nadalje v vrste (Zelenjava: Bučke, Čebula, Motovilec ...). Ko najde produkt, ki ga želi kupiti, izbere pakiranje
        in količino ter ga doda v košarico s klikom na gumb s košarico. Pri vsakem produktu si lahko ogleda sliko, opis
        in ponudnika, preden se odloči za nakup.
        <br>
        Aktualno ponudbo lahko izbira tudi na levi strani v zavihku Aktualna ponudba, ki deluje kot harmonika in kjer je
        napisano število izdelkov v ponudbi.
        <h4 class="mt-2">3. Pregled košarice</h4>
        Košarico najdemo v zgornjem desnem kotu vsake strani, kjer je izpisano tudi število artiklov v košarici in
        skupni znesek nakupa. S klikom na košarico se odpre nakupovalna košarica, v kateri so trenutni izbrani artikli,
        cene, davčne stopnje posameznih artiklov, količine izbranih pakiranj, stroški dostave in skupni znesek nakupa.
        Kupec lahko izbere tudi podatke, ki jih želi imeti na računu, v kolikor jih potrebuje. Iz košarice lahko artikle
        tudi odstrani.
        <h4 class="mt-2">4. Potrdimo nakup</h4>
        Oddajo ponudbe za nakup potrdi s klikom na gumb "POTRDI NAKUP".
        <br>
        Na podlagi potrjenega naročila se izdela Naročilnica, na kateri je povzetek nakupa ter datum, kraj in čas
        prevzema.
        <h4 class="mt-2">5. Prevzem in plačilo naročenih artiklov</h4>
        Kupec je dolžen poravnati kupnino, prevzeti nakupljene artikle in prevzeti račun na prevzemnem mestu, za
        katerega se je odločil na začetku nakupa in je naveden na Naročilnici.
        <h3 class="mt-2">Ponudniki</h3>
        Na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> lahko ponujajo svoje produkte le
        ponudniki, ki imajo sklenjeno pogodbo z
        upravljalcem portala. Upravljalec se bo trudil preverjati ponudnike in njihove produkte po svojih zmožnostih
        (obisk kmetije ali podjetja, ogled proizvodnje in reje živali, ogled pridelave in predelave pridelkov ipd.), saj
        je osnovni namen portala zagotavljati transparentnost in poštenost dobaviteljev.
        <br>
        Ponudnik po sklenitvi pogodbe vpiše svojo kmetijo ali podjetje na portal, vnese opis, značilnosti, posebnosti,
        ponudbo, doda slike in certifikate, v kolikor jih ima, šele nato lahko začne ponujati svoje produkte v prodajo.
        Ponudnik sam vpiše svoje produkte, jim določi količino, pakiranje, čas, ki ga potrebuje za pripravo, ter doda
        opis in ceno. Sam odgovarja za morebitne napake in nosi stroške, ki bi nastali v zvezi s tem. Da bi zmanjšali
        možnost napak, ponudnik vsak produkt najprej pregleda in šele nato potrdi za objavo.
        <br>
        Ponudniki imajo v vsakem trenutku pregled nad ažurnim stanjem prodaje njihovih produktov, pred dnevom dostave pa
        dobijo še elektronsko obvestilo o naročilu. Naročene artikle zapakirajo, ustrezno označijo in jih pripeljejo na
        dogovorjeni zbirni center ob dogovorjenem času, ki se jim izpiše na povezavi Moje poslovanje – Naročeno.
        <br>
        Identiteta ponudnika je za vsak produkt, ki se ponuja na internetni tržnici na portalu <a
        href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>
        nedvoumno označena.
        <h3 class="mt-2">Produkti</h3>
        Namen internetne tržnice na <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> je, kupcem zagotavljati
        dostop do sveže in kakovostne hrane
        in drugih produktov. Produkte v naši ponudbi vpisujejo ponudniki sami.
        <br>
        Produkti so znotraj posamezne kategorije razvrščeni naključno, da bi zagotavljali enake možnosti za vse
        ponudnike.
        <br>
        Ponudniki lahko na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> ponujajo samo produkte,
        za katere imajo sklenjeno pogodbo z
        upravljalcem portala. Vsak produkt ima eno ali več različnih pakiranj, vsako pakiranje ima lahko svojo ceno.
        Kupec izbere pakiranje in količino, nato doda izbrani produkt v košarico.
        <br>
        Vsak produkt ima zmeraj napisane bistvene značilnosti, dostopnost ter jasno in nedvoumno določeno ceno.
        <br>
        Nekateri produkti potrebujejo čas priprave. Le-ta je nedvoumno označen in pomeni, da produkta prej kot v
        določenem času ni moč dobiti. Če na primer v torek kupujemo za četrtek produkt, ki potrebuje tri dni časa za
        pripravo, ga za ta četrtek ne bomo mogli naročiti. Lahko ga bomo pa naročili za naslednji četrtek.
        <h3 class="mt-2">Cene</h3>
        Vse cene na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> so navedene v evrih ter že
        vsebujejo davek na dodano vrednost – DDV,
        razen če je izrecno napisano drugače. Stroški dostave niso vključeni v ceno produktov. Cene, objavljene na
        portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> se lahko spreminjajo. Cene produktov
        določajo ponudniki sami.
        <br>
        Cene veljajo v trenutku, ko je oddano naročilo prejel informacijski sistem ponudnika. Če se zgodi, da se
        artiklu, ki je že v košarici, pred potrdilom nakupa spremeni cena, je to jasno vidno z opozorilom in opozorilno
        barvo pri produktu v košarici. Kupec mora najprej sprejeti spremembe ali odstraniti artikel iz košaarice, preden
        lahko nadaljuje z nakupovanjem.
        <h3 class="mt-2">Pakiranja</h3>
        Ponudniki sami izberejo način pakiranja. Ker gre za predpakirane izdelke, mora vsak ponudnik zagotavljati
        ustreznost pakiranj, embalaže in označevanja živil po veljavni zakonodaji Slovenije in Evropske Unije.
        Upravitelj portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> se bo trudil preverjati
        ustreznost pakiranj, embalaže in označb na
        živilih ter portalu po svojih močeh, vendar je ne more zagotavljati, prav tako v zvezi s tem ne more prevzemati
        nobene odgovornosti. V zvezi s tem še poglavji: Vračilo in Reklamacije.
        <br>
        Ponudnik je odgovoren za vpis vseh potrebnih in obveznih informacij o živilu na stran o svojem produktu, ter za
        pravilno označitev predpakiranih izdelkov. Opredelitev „obveznih informacij o živilih“ vključuje vse
        informacije, ki morajo biti zagotovljene končnemu potrošniku v skladu z zakonodajo EU in slovensko zakonodajo.
        Ob dobavi blaga ponudnik posreduje vse obvezne podatke upravljalcu portala, ta pa kupcu.
        <br>
        Kupec je odgovoren, da informacije o živilu, predvsem pa o posebnih pogojih hranjenja oz. ravnanja z živilom ter
        alergenih vedno preveri ob prevzemu živila, saj se njihove sestavine v smislu vsebnosti alergenov lahko
        spremenijo.
        <h3 class="mt-2">Košarica</h3>
        Košarica na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> je enakovredna nakupovalni
        košarici ali vozičku v trgovini, s to
        razliko, da so artikli v njej do potrditve nakupa na voljo tudi drugim uporabnikom. Košarica kupca ne obvezuje k
        nakupu, plačilu ali prevzemu, artikli v njej se ne smatrajo za kupljene in niso rezervirani. Dokler nakup ni
        potrjen, jih lahko spreminja tudi ponudnik. Če do tega pride, so spremembe v košarici označene. V košarici so
        izpisane tudi davčne stopnje in morebitni stroški dostave. Kupec nakup potrdi s klikom na gumb "POTRDI NAKUP".
        <h3 class="mt-2">Potrdi nakup</h3>
        S klikom na gumb "POTRDI NAKUP" se kupec strinja s plačilom in prevzemom nakupa, obvestilo o nakupu dobi po
        e-pošti.
        <br>
        Sklenjena pogodba velja do izbranega roka za dostavo.
        <h3 class="mt-2">Plačilo</h3>
        Plačilo se izvede na tri načine:
        <ul>
            <li>v gotovini na prevzemnem mestu ob prevzemu naročenih artiklov;</li>
            <li>z nakazilom na račun upravljalca strani, navedenem na Naročilnici, ki jo uporabnik prejme ob oddaji
                naročila; plačilo na TRR upravljalca je mogoče samo vnaprej kot predplačilo.
            </li>
        </ul>
        S tem ko kupec upravljalcu strani nakaže denar za plačilo nakupa, se strinja, da se vsak morebitni višek spravi
        v njegovo e-denarnico.
        <br>
        Na dostavnem mestu sprejemamo bankovce vključno do vrednosti 100 €.
        <h3 class="mt-2">Veljavnost ponudbe</h3>
        Ponudniki spletne tržnice <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> sami vpisujejo svoje
        izdelke. Lahko jih neomejeno spreminjajo
        ali umaknejo iz ponudbe. Zato je vsakokratna ponudba na <a
        href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> aktualna v trenutku, ko si stran
        ogledamo, do potrditve nakupa pa se lahko podatki spremenijo. Če imamo v košarici spravljen izdelek, ki so se mu
        v času do potrditve nakupa podatki spremenili ali pa je bil iz ponudbe umaknjen ali ga je v tem času kupil kdo
        drug, bo na to ob potrditvi nakupa opozorjeno. V tem primeru stran zahteva preverbo podatkov in potrditev
        sprememb, preden zaključimo z nakupovanjem.
        <br>
        Potrditev naročila zaveže kupca k plačilu in prevzemu kupljenih artiklov, upravljalca portala
        <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> k zagotovitvi prevzemnega mesta ob izbranem času in
        na izbranem kraju, ponudnike pa k
        dostavi kupljenih artiklov. V primeru višje sile (če npr. pobiranje pridelka onemogoči vreme ipd.) za dostavo ne
        moremo odgovarjati. V tem primeru se kupcu ponudi podobne izdelke iz ponudbe oz. se mu denar naloži v
        e-denarnico ter ob zahtevi izplača oz. nakaže na račun.
        <h3 class="mt-2">Odstop od pogodbe/nakupa</h3>
        Pri pogodbah, sklenjenih na daljavo, ima potrošnik pravico, da v 14 dneh od prevzema blaga podjetju sporoči, da
        odstopa od pogodbe, ne da bi mu bilo treba navesti razlog za svojo odločitev. Edini strošek, ki bremeni
        potrošnika v zvezi z odstopom od pogodbe, je neposreden strošek vračila blaga. O vračilu nas pisno obvestite na
        info@smrk.si
        <br>
        Obvestilo o odstopu lahko predložite z obrazcem za vračilo ali z nedvoumno izjavo, iz katere je jasno razvidno,
        da odstopate od pogodbe. Da je potrošnik podal odstopno izjavo pravočasno, se šteje, če jo pošlje v roku,
        določenem za odstop od pogodbe. Dokazno breme glede uveljavljanja pravice do odstopa nosi potrošnik. Vračilo
        plačil mora podjetje opraviti nemudoma ali najkasneje v roku 14 dni po prejemu obvestila o odstopu od pogodbe.
        <br>
        Obrazec za odstop od pogodbe/nakupa najdete <a href="documents/Obrazec za vračilo izdelka_20210927.pdf"
                                                       target="_blank">tukaj</a>.
        <br>
        Kupec lahko odstopi od pogodbe/nakupa na prevzemnem mestu, po tem, ko pregleda artikle, ki jih je naročil, če
        imajo stvarne napake. Za vsak zavrnjeni artikel se izpolni kratka izjava – razlog zavrnitve, ki ga upravljalec
        posreduje ponudniku.
        <br>
        Od pogodbe/nakupa lahko kupec odstopi pred samim prevzemom tudi po elektronski pošti, a najkasneje en dan pred
        predvidenim dnevom prevzema.
        <br>
        V primeru odstopa od pogodbe/nakupa, ko je bilo blago že plačano, kupnina ostane v uporabnikovi e-denarnici,
        pripravljena na naslednji nakup. Če uporabnik želi izplačilo, se le-to izvede pod pogoji, zapisanimi pod točko
        E-denarnica.
        <h3 class="mt-2">Vračilo</h3>
        Kupec (velja izključno za fizične osebe, ki pridobijo blago za namene izven svoje pridobitne dejavnosti) ima
        možnost, da v roku 14 dni od prejema izdelka odstopi od pogodbe brez navedbe razloga in vrne izdelek. Izdelek se
        mora vrniti nepoškodovan, v originalni embalaži ter nespremenjeni količini v 14 dneh od odstopa od pogodbe.
        <br>
        Možnost odstopa od pogodbe ne velja za:
        <ul>
            <li>blago ali storitve, katerih cena je odvisna od nihanj na trgih, na katera podjetje nima vpliva in ki
                lahko nastopijo v roku odstopa od pogodbe;
            </li>
            <li>izdelke, ki so hitro pokvarljivi ali jim hitro poteče rok uporabe;</li>
            <li>izdelke, ki so izdelani po natančnih navodilih kupca ter prilagojeni njegovim osebnim potrebam;</li>
            <li>opravljanje storitev, če podjetje pogodbo v celoti izpolni ter se je opravljanje storitve začelo na
                podlagi potrošnikovega izrecnega predhodnega soglasja in s privolitvijo, da izgubi pravico do odstopa od
                pogodbe, ko jo podjetje v celoti izpolni;
            </li>
            <li>dobavo zapečatenih avdio- ali videoposnetkov in računalniških programov, če je potrošnik po dostavi
                odprl varnostni pečat;
            </li>
            <li>dobavo časopisov, periodičnega tiska ali revij razen pri naročniških pogodbah za dobavo takih
                publikacij;
            </li>
            <li>dobavo zapečatenega blaga, ki ni primerno za vračilo zaradi varovanja zdravja ali higienskih vzrokov, če
                je potrošnik po dostavi odprl pečat;
            </li>
            <li>dobavo blaga, ki je zaradi svoje narave neločljivo pomešano z drugimi predmeti;</li>
            <li>dobavo alkoholnih pijač, katerih cena je dogovorjena ob sklenitvi prodajne pogodbe in ki se lahko
                dostavijo po 30 dneh, njihova dejanska vrednost pa je odvisna od nihanj na trgu, na katera podjetje nima
                vpliva;
            </li>
            <li>izdelke, pri katerih je potrošnik izrecno zahteval obisk podjetja na domu zaradi izvedbe nujnega
                popravila ali vzdrževanja. Če podjetje pri takem obisku opravi še dodatne storitve, ki jih potrošnik ni
                izrecno zahteval, ali dostavi blago, ki ne predstavlja nadomestnih delov, nujno potrebnih za vzdrževanje
                ali popravilo, ima potrošnik za navedene dodatne storitve in blago pravico do odstopa od pogodbe;
            </li>
            <li>nastanitev, ki ni namenjena stalnemu bivanju, prevozu blaga, najemu vozil, pripravi in dostavi hrane,
                ali storitvah za prosti čas, pri katerih se podjetje zaveže, da bo izpolnilo svojo obveznost ob točno
                določenem datumu ali v točno določenem roku;
            </li>
            <li>dobavo digitalne vsebine, ki se ne dostavi na otipljivem nosilcu podatkov, če se je opravljanje storitve
                začelo na podlagi potrošnikovega izrecnega predhodnega soglasja in s privolitvijo, da s tem izgubi
                pravico do odstopa od pogodbe.
            </li>
        </ul>
        Kadar je pri pogodbi, sklenjeni zunaj poslovnih prostorov, blago dostavljeno na dom ob sklenitvi pogodbe, ga
        podjetje prevzame na lastne stroške, če zaradi narave blaga ni mogoče vračilo na običajen način po pošti.
        <h3 class="mt-2">Reklamacije</h3>
        Vsako reklamacijo vzamemo resno ter v skladu z določili Zakona o varstvu potrošnikov in jo posredujemo
        ponudniku. Ponudnik odgovarja za ustrezno pakiranje, kvaliteto in kvantiteto svojih artiklov ter primerno
        dostavo do upravljalca. V skladu z zakonom o Zdravstveni ustreznosti živil in izdelkov ter snovi, ki prihajajo v
        stik z živili, ravnamo v skladu z dobro higiensko prakso, katere cilj je zagotoviti varno hrano na
        najenostavnejši način.
        <br>
        Reklamacija se poda v elektronski obliki preko spletnega portala, po elektronski pošti upravitelju spletne
        strani ali v pisni obliki.
        <br>
        Ponudnik bo uporabnikovo reklamacijo rešil v osmih delovnih dneh oziroma mu posredoval razlago vzroka še
        morebitno nerešene reklamacije, in sicer na enak način, kot je bila reklamacija podana (v elektronski ali pisni
        obliki). Vse spore, ki ne bodo rešeni na ta način, bo reševalo pristojno sodišče v Gornji Radgoni oz. Murski
        Soboti.
        <h3 class="mt-2">Odgovornost za stvarne napake</h3>
        Prodajalec odgovarja za stvarne napake, ki jih je stvar imela takrat, ko je nevarnost prešla na kupca, ne glede
        na to, ali mu je bilo to znano ali ne. Prodajalec odgovarja tudi za tiste stvarne napake, ki se pokažejo potem,
        ko je nevarnost prešla na kupca, če so posledica vzroka, ki je obstajal že pred tem. Neznatna stvarna napaka se
        ne upošteva. Natančneje pravico do uveljavljanja stvarnih napak ureja Zakon o varstvu potrošnikov.
        <h3 class="mt-2">Garancija</h3>
        Artikli imajo garancijo, če je tako navedeno na garancijskem listu ali na računu. Garancija je veljavna ob
        upoštevanju navodil na garancijskem listu ter ob predložitvi računa. Garancijski roki so navedeni na
        garancijskih listih ali na računu. Informacija o garanciji je lahko navedena tudi na strani s predstavitvijo
        artikla. Če informacije o garanciji ni, artikel nima garancije ali pa podatek v tem trenutku ni znan. V slednjem
        primeru lahko kupec kontaktira ponudnika, ki bo zagotovil ažurno informacijo.
        <br>
        Proizvajalec je v skladu z zakonom dolžan kupcu zagotoviti garancijo za brezhibno delovanje kupljenega blaga.
        Garancijo lahko kupec uveljavlja neposredno pri proizvajalcu ali njegovem pooblaščenem servisu. Garancijo lahko
        kupec uveljavlja z garancijskim listom in računom ponudnika.
        <br>
        Proizvajalec je dolžan garancijsko popravilo opraviti v 45 dneh od prejema blaga, v nasprotnem primeru pa
        artikel zamenjati z drugim, enakovrednim in brezhibnim artiklom. Kupec lahko garancijo uveljavlja tudi pri
        ponudniku, vendar tudi v tem primeru velja določilo iz prejšnjega odstavka.
        <br>
        Vračilo blaga v garancijo se opravi v skladu s pogoji, ki jih proizvajalec navede na garancijskem listu, kupec z
        vračilom nima stroškov.
        <h3 class="mt-2">Omejitev odgovornosti</h3>
        Upravitelj portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> se bo po najboljših močeh trudil
        zagotavljati točne in ažurne podatke
        na spletišču, vendar uporabnika opozarja, da so besedila informativnega značaja, zato ne jamči in ne prevzema
        nobene odgovornosti za njihovo točnost oziroma ažurnost. Upravljavec prav tako ne prevzema odgovornosti za
        dejanja, ki jih uporabnik opravi na podlagi objavljenih podatkov.
        <br>
        Upravitelj ni odgovoren za občasno nedelovanje strani in tudi ne za morebitno škodo, nastalo zaradi uporabe
        netočnih ali nepopolnih informacij. Niti upravljavec, niti katerakoli druga pravna ali fizična oseba, ki je
        sodelovala pri nastanku in izdelavi spletišča, ne odgovarja za škodo, ki bi izhajala iz dostopa do informacij,
        uporabe ali nemožnosti uporabe informacij na teh spletnih straneh ali za kakršnekoli napake ali pomanjkljivosti
        v njihovi vsebini.
        <br>
        Upravitelj si pridržuje pravico, da kadarkoli spremeni ali odstrani vsebino teh spletnih strani, ne glede na
        razlog ter brez predhodnega opozorila. Uporabniki objavljene vsebine uporabljajo na lastno odgovornost.
        Upravljavec ne odgovarja za nobene posredne ali neposredne poškodbe ali izgube informacij, do katerih bi lahko
        prišlo pri uporabi in prebiranju internetnih strani, ki niso v upravljavčevi lasti. Uporabnik je pri izbiri
        drugih internetnih strani, na katere se lahko poveže na druge spletne strani preko spletišča, popolnoma
        samostojen in v celoti odgovoren.
        <br>
        Za vsebine, ki jih objavljajo ponudniki, odgovarjajo izključno ponudniki. Odgovornost zajema slikovno gradivo in
        vse informacije o njihovih kmetijah ali podjetjih ter produktih, ki jih ponujajo na portalu
        <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>.
        <br>
        Na slikah, v opisu, sestavinah in/ali posebnih zahtevah produktov so podane predpisane informacije o živilu ter
        navodila za ravnjanje. Med predpisanimi podatki so tudi variabilni podatki, kot so rok uporabnosti ipd., zato
        mora kupec sam preveriti označbo živila, ki ga bo prejel, saj so tam so navedeni vsi obvezni podatki in zanje
        odgovarja izključno ponudnik (proizvajalec) in ne upravitelj portala. Slike so simbolične, ponudniki in
        upravitelj portala si pridržujejo pravico do tipkarskih napak.
        <h3 class="mt-2">Avtorske pravice</h3>
        Vsebina spletišča je avtorsko delo, katere imetnik avtorskih pravic je upravljavec, in kot taka je predmet
        avtorske zaščite ali druge oblike zaščite intelektualne lastnine. V avtorskopravno in z drugimi pravicami
        intelektualne lastnine varovano vsebino spada poleg besedila in podatkov tudi celotna grafična podoba spletnega
        mesta z vsemi grafičnimi elementi.
        <br>
        Uporabnikom je dovoljeno uporabljati objavljene vsebine samo v osebne in nekomercialne namene. Prepovedano je
        vsakršno drugo razpolaganje z vsebino, ki zajema, a ne izključno, spreminjanje, kopiranje, objavljanje
        katerekoli vsebine v celoti ali delno na drugih mestih, razen če v to upravljavec izrecno dovoli v pisni obliki.
        Če uporabnik posreduje kakršnokoli gradivo upravljavcu, brezplačno prenaša vse materialne avtorske pravic in
        dovoljuje uporabo ter objavo posredovanega gradiva.
        <h3 class="mt-2">Pritožbe in spori</h3>
        Upravitelj portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> spoštuje veljavno zakonodajo o
        varstvu potrošnikov in se po najboljših
        močeh trudi izpolnjevati svojo dolžnost vzpostavitve učinkovitega sistema obravnavanja pritožb. Pritožba se odda
        preko e-poštnega naslova ali pisno na naslov ponudnika, razvidnega na spletnih straneh upravitelja portala.
        Postopek obravnave pritožbe je zaupen.
        <br>
        Upravitelj portala se zaveda, da je bistvena značilnost potrošniškega spora, vsaj kar zadeva sodno reševanje,
        njegova nesorazmernost med ekonomsko vrednostjo zahtevka in stroški, ki nastanejo pri reševanju samega spora. To
        je tudi glavna ovira, da kupec/potrošnik ne sproži spora pred sodiščem. Zato si ponudnik prizadeva po svojih
        najboljših močeh, da se morebitni spori rešijo sporazumno oz. z izvensodno poravnavo, če pa to ne bi bilo možno,
        bo spore reševalo stvarno pristojno sodišče v Gornji Radgoni oz. Murski Soboti.
        <h4 class="mt-2">Izvensodno reševanje potrošniških sporov</h4>
        Skladno z zakonskimi normativi upravitelj portala ne priznava nikakršnega izvajalca izvensodnega reševanja
        potrošniških sporov kot pristojnega za reševanje potrošniškega spora, ki bi ga potrošnik lahko sprožil v skladu
        z Zakonom o izvensodnem reševanju potrošniških sporov.
        <br>
        Upravljalec, ki kot ponudnik blaga in storitev omogoča spletno trgovino na območju RS, na svoji spletni strani
        objavlja elektronsko povezavo na platformo za spletno reševanje potrošniških sporov (SRPS). Platforma je na
        voljo potrošnikom tukaj: https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=SL
        <br>
        Navedena ureditev izhaja iz Zakona o izvensodnem reševanju potrošniških sporov, Uredbe (EU) št. 524/2013
        Evropskega parlamenta in Sveta o spletnem reševanju potrošniških sporov ter spremembi Uredbe (ES) št. 2016/2004
        in Direktive 2009/22/ES.
        <h3 class="mt-2">Pravno obvestilo</h3>
        Upravljalec portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> si pridržuje pravico, da
        uporabniku onemogoči nadaljnje nakupovanje
        in/ali uporabo portala, če je uporabnik kršil Splošne pogoje poslovanja ali druge akte portala.
        <br>
        Ti splošni pogoji začnejo veljati z dnem 27. 9. 2021 in veljajo do preklica.
        <br><br>
        Datum zadnje spremembe: 27. 9. 2021


    </div>
</template>

<script lang="">
export default {
name: "Spp",
        components: {},
        props: {},
        data() {
            return {
            items: [
          { 'Država': 'Belgija', 'do 29,99 €': '9,95 €', '30,00–49,99 €': '7,95 €', '50,00–100,00 €': '5,95 €', 'nad 100 €': '2,95 €' },
          { 'Država': 'Bolgarija', 'do 29,99 €': '19,95 €', '30,00–49,99 €': '17,95 €', '50,00–100,00 €': '15,95 €', 'nad 100 €': '12,95 €' },
          { 'Država': 'Češka', 'do 29,99 €': '12,95 €', '30,00–49,99 €': '10,95 €', '50,00–100,00 €': '8,95 €', 'nad 100 €': '5,95 €' },
          { 'Država': 'Danska', 'do 29,99 €': '13,95 €', '30,00–49,99 €': '11,95 €', '50,00–100,00 €': '9,95 €', 'nad 100 €': '6,95 €' },
          { 'Država': 'Estonija', 'do 29,99 €': '17,95 €', '30,00–49,99 €': '15,95 €', '50,00–100,00 €': '13,95 €', 'nad 100 €': '10,95 €' },
          { 'Država': 'Finska', 'do 29,99 €': '22,95 €', '30,00–49,99 €': '20,95 €', '50,00–100,00 €': '18,95 €', 'nad 100 €': '15,95 €' },
          { 'Država': 'Francija', 'do 29,99 €': '14,95 €', '30,00–49,99 €': '12,95 €', '50,00–100,00 €': '10,95 €', 'nad 100 €': '7,95 €' },
          { 'Država': 'Grčija', 'do 29,99 €': '24,95 €', '30,00–49,99 €': '22,95 €', '50,00–100,00 €': '20,95 €', 'nad 100 €': '7,95 €' },
          { 'Država': 'Hrvaška', 'do 29,99 €': '5,00 €', '30,00–49,99 €': '3,00 €', '50,00–100,00 €': '0 €', 'nad 100 €': '0 €' },
          { 'Država': 'Irska', 'do 29,99 €': '15,95 €', '30,00–49,99 €': '13,95 €', '50,00–100,00 €': '11,95 €', 'nad 100 €': '8,95 €' },
          { 'Država': 'Italija', 'do 29,99 €': '13,95 €', '30,00–49,99 €': '11,95 €', '50,00–100,00 €': '9,95 €', 'nad 100 €': '6,95 €' },
          { 'Država': 'Latvija', 'do 29,99 €': '17,95 €', '30,00–49,99 €': '15,95 €', '50,00–100,00 €': '13,95 €', 'nad 100 €': '10,95 €' },
          { 'Država': 'Litva', 'do 29,99 €': '17,95 €', '30,00–49,99 €': '15,95 €', '50,00–100,00 €': '13,95 €', 'nad 100 €': '10,95 €' },
          { 'Država': 'Luksemburg', 'do 29,99 €': '12,95 €', '30,00–49,99 €': '10,95 €', '50,00–100,00 €': '8,95 €', 'nad 100 €': '5,95 €' },
          { 'Država': 'Madžarska', 'do 29,99 €': '9,95 €', '30,00–49,99 €': '7,95 €', '50,00–100,00 €': '5,95 €', 'nad 100 €': '2,95 €' },
          { 'Država': 'Nizozemska', 'do 29,99 €': '10,95 €', '30,00–49,99 €': '8,95 €', '50,00–100,00 €': '6,95 €', 'nad 100 €': '3,95 €' },
          { 'Država': 'Poljska', 'do 29,99 €': '12,95 €', '30,00–49,99 €': '10,95 €', '50,00–100,00 €': '8,95 €', 'nad 100 €': '5,95 €' },
          { 'Država': 'Portugalska', 'do 29,99 €': '20,95 €', '30,00–49,99 €': '18,95 €', '50,00–100,00 €': '16,95 €', 'nad 100 €': '13,95 €' },
          { 'Država': 'Romunija', 'do 29,99 €': '21,95 €', '30,00–49,99 €': '19,95 €', '50,00–100,00 €': '17,95 €', 'nad 100 €': '14,95 €' },
          { 'Država': 'Slovaška', 'do 29,99 €': '13,95 €', '30,00–49,99 €': '11,95 €', '50,00–100,00 €': '9,95 €', 'nad 100 €': '6,95 €' },
          { 'Država': 'Španija', 'do 29,99 €': '18,95 €', '30,00–49,99 €': '16,95 €', '50,00–100,00 €': '14,95 €', 'nad 100 €': '11,95 €' },
          { 'Država': 'Švedska', 'do 29,99 €': '17,95 €', '30,00–49,99 €': '15,95 €', '50,00–100,00 €': '13,95 €', 'nad 100 €': '10,95 €' },
          { 'Država': 'Velika Britanija', 'do 29,99 €': '13,95 €', '30,00–49,99 €': '11,95 €', '50,00–100,00 €': '9,95 €', 'nad 100 €': '6,95 €' },







        ]
            }
        },
        mounted() {},
        methods: {},
        computed: {},
        filters: {},
        watch: {}
}










</script>

<style scoped>

</style>
