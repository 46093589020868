import { render, staticRenderFns } from "./Spp.vue?vue&type=template&id=08b69c7e&scoped=true&"
import script from "./Spp.vue?vue&type=script&lang=true&"
export * from "./Spp.vue?vue&type=script&lang=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b69c7e",
  null
  
)

export default component.exports